<template>
    <div>
        <div>
            <div class="div-logo text-center">
                <img :src="srcLogo" />
            </div>
            <div class="div-download">
                <Molded>
                    <Loading :center="false" v-if="loading" />
                    <div v-else>
                        <br>
                        <div class="div-text text-center">
                            <i class="fa-regular fa-share-nodes"></i>
                            <span class="title"> Clique abaixo para baixar os arquivos compartilhados...</span>
                        </div>
                        <br>
                        <div class="div-molded text-center">
                            <div class="side-by-side">
                                <Button v-if="documentId != 'null'" _key="btnDownload" type="primary" title="Documento"
                                    classIcon="fa-solid fa-download" size="small" :clicked="downloadDocument" />
                            </div>
                            <div class="side-by-side">
                                <Button v-if="billetId != 'null'" _key="btnDownload" type="primary" title="Boleto"
                                    classIcon="fa-solid fa-download" size="small" :clicked="downloadBillet" />
                            </div>
                        </div>
                    </div>
                </Molded>
            </div>
        </div>
    </div>
</template>
<script>

import Molded from "@nixweb/nixloc-ui/src/component/layout/Molded";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import Loading from "@nixweb/nixloc-ui/src/component/shared/Loading.vue";

import { mapMutations, mapActions } from "vuex";

export default {
    name: "DocumentPreviewPublic",
    components: {
        Molded, Button, Loading
    },
    data() {
        return {
            urlGetLogo: "/api/v1/adm/company/get-logo",
            documentId: this.$route.params.documentId,
            billetId: this.$route.params.billetId,
            logo: this.$route.params.logo,
            loading: true,
        }
    },
    mounted() {
        this.getLogo();

        let self = this;
        setTimeout(function () {
            self.loading = false;
        }, 1000);
    },
    computed: {
        srcLogo() {
            return `https://espaco.blob.core.windows.net/nixloc-logo-company/${this.logo}`;
        },
    },
    methods: {
        ...mapMutations("generic", ["removeLoading"]),
        ...mapActions("generic", ["getApi"]),
        getLogo() {
            let params = {
                url: this.urlGetLogo,
                obj: { id: this.logo },
            };
            this.getApi(params).then((response) => {
                this.logo = response.content == "" ? "noImage.png" : response.content;
            });
        },
        downloadDocument() {
            this.download(this.documentId, "documento");
        },
        downloadBillet() {
            this.download(this.billetId, "boleto");
        },
        download(urlFile, nameFile) {
            this.removeLoading(["btnDownload"]);
            const link = document.createElement('a');
            link.href = `https://espaco.blob.core.windows.net/nixloc-preview/${urlFile}.pdf`;
            link.target = '_blank';
            link.download = `${nameFile}.pdf`;

            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
        }
    }
}
</script>
<style scoped>
img {
    width: 200px;
}

.div-text {
    font-size: 16px;
    margin-bottom: 20px;
}

.div-logo {
    margin-top: 50px;
    margin-bottom: 50px;
}

.div-molded {
    min-height: 100px;
}

.div-download {
    margin: auto;
    max-width: 900px;
}
</style>